import { WithContext, WebPage } from 'schema-dts'
import { generalAudience } from './general'

export function JsonLdBlog(): WithContext<WebPage> {
    return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        '@id': 'https://tebuto.de/#blog',
        isPartOf: {
            '@id': 'https://tebuto.de/#website'
        },
        publisher: {
            '@id': 'https://tebuto.de/#organization'
        },
        creator: {
            '@id': 'https://tebuto.de/#organization'
        },
        inLanguage: 'de',
        name: 'Tebuto Blog',
        description: 'Der Tebuto Blog bietet psychologische Fachartikel und Ratgeber für Klient:innen und Therapeut:innen.',
        url: 'https://tebuto.de/blog',
        audience: generalAudience,
        potentialAction: [
            {
                '@type': 'ReadAction',
                target: 'https://tebuto.de/blog',
                name: 'Psychologischer Blog'
            }
        ]
    }
}
