/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
import type { ContactInfo } from './ContactInfo';
import type { Contract } from './Contract';
import type { Opening } from './Opening';
import type { TherapistCharacteristic } from './TherapistCharacteristic';
import type { TherapistInformationOption } from './TherapistInformationOption';
import type { TherapistLanguage } from './TherapistLanguage';
import type { TherapistLink } from './TherapistLink';
import type { TherapistSettings } from './TherapistSettings';
import type { TherapistUserLinkingWithUserInfo } from './TherapistUserLinkingWithUserInfo';
export type Therapist = {
    id: number;
    name: string;
    description: string | null;
    slug: string;
    uuid: string;
    firstName: string;
    lastName: string;
    title: string | null;
    gender: Therapist.gender;
    image: string | null;
    directoryEntryEnabled: boolean;
    welcomeTourDone: boolean;
    generated: boolean;
    acceptingNewClients: Therapist.acceptingNewClients;
    link: TherapistLink | null;
    address: Address;
    contract: Contract;
    contactInfos: Array<ContactInfo>;
    openings: Array<Opening>;
    languages: Array<TherapistLanguage>;
    users: Array<TherapistUserLinkingWithUserInfo>;
    settings: TherapistSettings;
    informationSelections: Array<TherapistInformationOption>;
    characteristics: Array<TherapistCharacteristic>;
    badges: Array<'online' | 'booking'>;
};
export namespace Therapist {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
    export enum acceptingNewClients {
        NO = 'no',
        YES = 'yes',
        WAIT1TO2WEEKS = 'wait1To2Weeks',
        WAIT2TO4WEEKS = 'wait2To4Weeks',
        WAIT2MONTHS = 'wait2Months',
        WAIT3MONTHS = 'wait3Months',
        WAIT6MONTHS = 'wait6Months',
        WAIT1YEAR = 'wait1year',
    }
}

