import {
    CalendarDateRangeIcon,
    CalendarIcon,
    ChatBubbleLeftRightIcon,
    ComputerDesktopIcon,
    CursorArrowRaysIcon,
    LockClosedIcon,
    ScaleIcon,
    WifiIcon
} from '@heroicons/react/24/outline'
import COMPONENT_TEXTS from '@tebuto/translations'

export const featuresList = [
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.href,
        icon: ComputerDesktopIcon,
        image: '/assets/screenshots/termine-buchen.webp',
        mobileImage: '/assets/screenshots/termine-buchen-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.publicBooking.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.publicBooking.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.publicBooking.href,
        icon: CursorArrowRaysIcon,
        image: '/assets/screenshots/terminbuchung-widget.webp',
        mobileImage: '/assets/screenshots/terminbuchung-widget-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.href,
        icon: CalendarDateRangeIcon,
        image: '/assets/screenshots/termine.webp',
        mobileImage: '/assets/screenshots/termine-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.href,
        icon: WifiIcon,
        image: '/assets/screenshots/online-meetings.webp',
        mobileImage: '/assets/screenshots/online-meetings-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.href,
        icon: ScaleIcon,
        image: '/assets/screenshots/ausfallhonorar.webp',
        mobileImage: '/assets/screenshots/ausfallhonorar-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.href,
        icon: CalendarIcon,
        image: '/assets/screenshots/kalender.webp',
        mobileImage: '/assets/screenshots/kalender-mobile.webp'
    },

    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.href,
        icon: ChatBubbleLeftRightIcon,
        image: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen.webp',
        mobileImage: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen-mobile.webp'
    },
    {
        name: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.title,
        subtitle: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.subtitle,
        href: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.href,
        icon: LockClosedIcon,
        image: '/assets/screenshots/datenschutz.webp',
        mobileImage: '/assets/screenshots/datenschutz-mobile.webp'
    }
]
