/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
export type MinimalPublicTherapist = {
    slug: string;
    name: string;
    image: string | null;
    description: string | null;
    title: string | null;
    firstName: string | null;
    lastName: string | null;
    gender: MinimalPublicTherapist.gender | null;
    generated: boolean;
    acceptingNewClients: MinimalPublicTherapist.acceptingNewClients;
    address: Address;
};
export namespace MinimalPublicTherapist {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
    export enum acceptingNewClients {
        NO = 'no',
        YES = 'yes',
        WAIT1TO2WEEKS = 'wait1To2Weeks',
        WAIT2TO4WEEKS = 'wait2To4Weeks',
        WAIT2MONTHS = 'wait2Months',
        WAIT3MONTHS = 'wait3Months',
        WAIT6MONTHS = 'wait6Months',
        WAIT1YEAR = 'wait1year',
    }
}

