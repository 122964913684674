'use client'

import { CheckIcon } from '@heroicons/react/24/outline'
import { GetFreeTrialButton } from './GetFreeTrialButton'
import { Heading, LandingSection } from './LandingComponents'
import { Matomo } from '@tebuto/providers/Matomo'

export default function Pricing() {
    return (
        <LandingSection id={'preis'} className="!px-0">
            <Matomo.ScrollTrackingAnchor sectionName={'Preis'} />
            <div className="from-primary-500 to-primary-600 bg-gradient-to-r py-12 md:px-12 px-6 xl:rounded-3xl xl:shadow-md">
                <div className="flex flex-col 2xl:gap-10 gap-6">
                    <div className="flex justify-between 2xl:gap-10 gap-6">
                        <Heading className="text-white">Ein Preis. Keine Tricks.</Heading>
                        <div>
                            <Heading className="text-white">69€</Heading>
                            <p className="text-landing font-normal tracking-wider !text-white">pro Monat</p>
                        </div>
                    </div>

                    <p className="text-landing text-primary-100">
                        Wir möchten <b className="text-landing text-primary-100">Ihre Arbeit einfacher machen</b>. Bei uns gibt es keine Premium-Upgrades, Langzeit-Verträge oder
                        versteckte Kosten. Neue Funktionen sind automatisch für alle unsere Nutzer und ohne Aufpreis verfügbar.
                    </p>
                    <p className="text-landing text-primary-100">
                        Dabei <b className="text-landing text-primary-100">kostet Tebuto nur etwa so viel wie eine Stunde beim Therapeuten</b>. Testen Sie uns 
                        <b className="text-landing text-primary-100">30 Tage kostenlos</b>. Anschließend erfolgt die Bezahlung bequem monatlich und Sie können{' '}
                        <b className="text-landing text-primary-100">jederzeit kündigen</b>.
                    </p>
                </div>
                <div className="flex flex-col gap-12 mt-10 scroll-mt-12">
                    <div>
                        <div className="flex items-center gap-4">
                            <span className="text-lg font-bold text-primary-100 flex-shrink-0">Was ist drin?</span>
                            <div className="w-full border-b border-primary-400" />
                        </div>
                        <div>
                            <ul className="mt-10 grid md:grid-cols-2 gap-4">
                                <li className="flex items-center text-landing text-primary-100 gap-2">
                                    <CheckIcon className="h-6 w-6 stroke-2" /> Unbegrenzte Anzahl an Klientinnen und Terminen
                                </li>
                                <li className="flex items-center text-landing text-primary-100 gap-2">
                                    <CheckIcon className="h-6 w-6 stroke-2" /> Zugriff auf alle Funktionen und Integrationen
                                </li>
                                <li className="flex items-center text-landing text-primary-100 gap-2">
                                    <CheckIcon className="h-6 w-6 stroke-2" /> Unbegrenzte Kalender und Benachrichtigungen
                                </li>
                                <li className="flex items-center text-landing text-primary-100 gap-2">
                                    <CheckIcon className="h-6 w-6 stroke-2" /> Weltweit und auf jedem Gerät
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full">
                        <GetFreeTrialButton className="text-landing block font-medium !text-primary-600 bg-primary-50 py-3 w-full rounded-lg text-center" />
                    </div>
                </div>
            </div>
        </LandingSection>
    )
}
