'use client'

import { useOpenCookieBanner } from '@tebuto/react-gdpr-cookie-consent'
import COMPONENT_TEXTS from '@tebuto/translations'
import Image from 'next/image'
import Link from 'next/link'
import Container from '../Container'
import { socialProfiles } from './socialData'

type MenuItem = MenuLink | MenuAction

interface MenuLink {
    name: string
    href: string
    target?: string
    Icon?: any
}

interface MenuAction {
    name: string
    onClick: () => void
}

interface Menu {
    features: MenuItem[]
    support: MenuItem[]
    links: MenuItem[]
    legal: MenuItem[]
    social: MenuItem[]
}

function Item({ item }: { item: MenuItem }) {
    if ('onClick' in item) {
        return (
            <li key={item.name} className="list-none">
                <button type="button" onClick={item.onClick} className="text-base text-primary-100 dark:text-primary-700 hover:text-primary-200">
                    {item.name}
                </button>
            </li>
        )
    }

    if (!item.Icon) {
        return (
            <li key={item.name} className="list-none">
                <Link
                    href={item.href.startsWith('https://') ? item.href : process.env.NEXT_PUBLIC_LANDING_BASE_URL + item.href}
                    target={item.target}
                    className="text-base text-primary-100 dark:text-primary-700 hover:text-primary-200"
                >
                    {item.name}
                </Link>
            </li>
        )
    }
    return (
        <Link key={item.name} href={item.href.startsWith('https://') ? item.href : process.env.NEXT_PUBLIC_LANDING_BASE_URL + item.href} target="_blank">
            <item.Icon className="h-8 w-8 mb-2 text-primary-200 dark:text-primary-700" aria-hidden="true" alt={item.name} />
            <span className="sr-only">{item.name}</span>
        </Link>
    )
}

export function Footer() {
    const handleOpenCookieBanner = useOpenCookieBanner()

    const navigation: Menu = {
        features: [
            { name: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.title, href: COMPONENT_TEXTS.layout.landing.menu.features.appointmentManagement.href },
            { name: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.title, href: COMPONENT_TEXTS.layout.landing.menu.features.cancellationFee.href },
            { name: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.title, href: COMPONENT_TEXTS.layout.landing.menu.features.onlineMeeting.href },
            { name: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.title, href: COMPONENT_TEXTS.layout.landing.menu.features.calendarIntegration.href },
            { name: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.title, href: COMPONENT_TEXTS.layout.landing.menu.features.clientBooking.href },
            { name: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.title, href: COMPONENT_TEXTS.layout.landing.menu.features.individualNotifications.href },
            { name: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.title, href: COMPONENT_TEXTS.layout.landing.menu.features.dataProtection.href }
        ],
        support: [
            { name: COMPONENT_TEXTS.layout.landing.menu.contact, href: '/kontakt' },
            { name: COMPONENT_TEXTS.layout.landing.menu.documentation, href: '/dokumentation' }
            // { name: 'Ticket', href: '#' },
            // { name: 'Chat', href: '#' }
        ],
        links: [
            { name: COMPONENT_TEXTS.layout.landing.menu.aboutUs, href: '/ueber-uns' },
            { name: COMPONENT_TEXTS.layout.landing.menu.createEntry, href: '/registrieren' },
            { name: COMPONENT_TEXTS.layout.landing.menu.roadmap, href: '/roadmap' },
            { name: COMPONENT_TEXTS.layout.landing.menu.register, href: '/registrieren' }
            // { name: 'Login', href: '#' },
            // { name: 'Presse', href: '#' }
        ],
        legal: [
            { name: COMPONENT_TEXTS.layout.landing.menu.impress, href: '/impressum' },
            { name: COMPONENT_TEXTS.layout.landing.menu.privacyDeclaration, href: '/datenschutzerklaerung' },
            { name: COMPONENT_TEXTS.layout.landing.menu.terms, href: '/agb' },
            { name: COMPONENT_TEXTS.layout.landing.menu.avv, href: '/avv' },
            { name: COMPONENT_TEXTS.layout.landing.menu.cookieSettings, onClick: handleOpenCookieBanner }
        ],
        social: socialProfiles
    }

    return (
        <div className="bg-footerBg dark:bg-blend-overlay dark:bg-primary-1100 min-h-full min-w-full bg-no-repeat bg-cover xl:pt-20 pt-48 max-md:pt-64 relative">
            <footer aria-labelledby="footer-heading">
                <Container className="pb-14 2xl:pt-14 max-2xl:pt-32">
                    <h2 id="footer-heading" className="sr-only">
                        {COMPONENT_TEXTS.layout.landing.footerTitle}
                    </h2>
                    <div className="xl:grid 2xl:grid-cols-3 xl:gap-8 mt-12">
                        <div className="space-y-8 xl:col-span-1 max-w-sm">
                            <Image className="h-14" width={256} height={256} src="/assets/logoDarkBg.svg" alt="Tebuto Logo" priority />
                            <p className="text-primary-200 dark:text-primary-700">{COMPONENT_TEXTS.layout.landing.slogan}</p>
                            <div className="flex space-x-6">
                                {navigation.social.map(item => (
                                    <Item key={item.name} item={item} />
                                ))}
                            </div>
                        </div>
                        <div className="mt-12 grid md:grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                            <div className="md:grid lg:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold text-white dark:text-primary-600  tracking-wider uppercase">
                                        {COMPONENT_TEXTS.layout.landing.menu.features.title}
                                    </h3>
                                    <ul className="mt-4 space-y-2">
                                        {navigation.features.map(item => (
                                            <Item key={item.name} item={item} />
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold text-white dark:text-primary-600  tracking-wider uppercase">
                                        {COMPONENT_TEXTS.layout.landing.menu.support}
                                    </h3>
                                    <ul className="mt-4 space-y-2">
                                        {navigation.support.map(item => (
                                            <Item key={item.name} item={item} />
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid lg:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold text-white dark:text-primary-600  tracking-wider uppercase">
                                        {COMPONENT_TEXTS.layout.landing.menu.links}
                                    </h3>
                                    <ul className="mt-4 space-y-2">
                                        {navigation.links.map(item => (
                                            <Item key={item.name} item={item} />
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold text-white dark:text-primary-600 tracking-wider uppercase">{COMPONENT_TEXTS.layout.landing.menu.law}</h3>
                                    <ul className="mt-4 space-y-2">
                                        {navigation.legal.map(item => (
                                            <Item key={item.name} item={item} />
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <Link href="https://heydata.eu" target="_blank" title="heyData GDPR seal" rel="noreferrer noopener" className="w-max">
                                <Image
                                    alt="heyData trusted logo"
                                    src="https://privacy-seal.heydata.eu/seal/80c7a878-b096-4897-9435-d5d07e0c9d3f?lang=de&design=design2&sealType=GDPR"
                                    className="border-none h-auto w-48"
                                    width="192"
                                    height="192"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="mt-12 border-t border-primary-400 dark:border-primary-900 pt-8">
                        <p className="text-sm text-primary-300 dark:text-primary-800 text-left tracking-wide font-light">&copy; {COMPONENT_TEXTS.layout.copyright}</p>
                    </div>
                </Container>
            </footer>
        </div>
    )
}
