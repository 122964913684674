import { classNames } from '@tebuto/functions/css'

interface MandatoryProps {
    mandatory?: boolean
    absolute?: boolean
}

export default function Mandatory({ mandatory = false, absolute = true }: MandatoryProps): JSX.Element {
    if (mandatory) {
        return <span className={classNames('text-sm font-medium text-red-400', absolute ? 'absolute -top-1 right-0' : '')}> *</span>
    }
    return <></>
}
