/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClientEvent = {
    start: string;
    end: string;
    id: number;
    customTitle: string | null;
    status: ClientEvent.status;
    isCustom: boolean;
};
export namespace ClientEvent {
    export enum status {
        OPEN = 'open',
        BOOKED = 'booked',
        APPROVED = 'approved',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
        OUTAGE = 'outage',
        SKIPPED = 'skipped',
    }
}

