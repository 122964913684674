import { Person, WebPage, WithContext, Comment } from 'schema-dts'
import { generalAudience, therapistAudience, userAudience } from './general'
import { JsonLdWebPageProps } from './types'

export function JsonLdWebPage({
    title,
    relativeUrl,
    description,
    author,
    commentCount,
    upvoteCount,
    dateCreated,
    dateModified,
    datePublished,
    customId,
    comments = [],
    audience = 'both'
}: JsonLdWebPageProps): WithContext<WebPage> {
    const selectedAudience = audience === 'both' ? generalAudience : audience === 'therapist' ? therapistAudience : userAudience

    return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        '@id': customId ?? `https://tebuto.de/#${relativeUrl}`,
        name: title,
        url: `https://tebuto.de${relativeUrl}`,
        description: description,
        isPartOf: {
            '@id': 'https://tebuto.de/#website'
        },
        publisher: {
            '@id': 'https://tebuto.de/#organization'
        },
        interactionStatistic:
            upvoteCount || upvoteCount === 0 // Avoid undefined when upvoteCount is 0, which is valid
                ? {
                      '@type': 'InteractionCounter',
                      name: 'Upvotes',
                      interactionType: {
                          '@type': 'VoteAction',
                          name: 'Upvote'
                      },
                      userInteractionCount: upvoteCount
                  }
                : undefined,
        comment: comments.map(comment => ({
            '@type': 'Comment',
            text: comment.text,
            dateCreated: comment.dateCreated,
            author: {
                '@type': 'Person',
                name: comment.author.name
            }
        })) as Comment[],
        dateCreated: dateCreated,
        dateModified: dateModified,
        datePublished: datePublished,
        commentCount: commentCount,
        author: author
            ? ({
                  '@type': 'Person',
                  ...author
              } as Person)
            : {
                  '@id': 'https://tebuto.de/#organization'
              },
        audience: selectedAudience,
        about: {
            '@type': 'Thing',
            name: title
        }
    }
}
