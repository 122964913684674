// Types

export type RuntimeEnvConfig = {
    flags: {
        connectZoomEnabled: boolean
        connectMicrosoftTeamsEnabled: boolean
        connectGoogleCalendarEnabled: boolean
        connectOutlookEnabled: boolean
        publicBookingEnabled: boolean
    }
}

export type LoadedRuntimeEnvConfig = {
    flags: {
        connectZoomEnabled?: boolean
        connectMicrosoftTeamsEnabled?: boolean
        connectGoogleCalendarEnabled?: boolean
        connectOutlookEnabled?: boolean
        publicBookingEnabled?: boolean
    }
}

// Constants

export const DEFAULT_ENV_VARIABLES: RuntimeEnvConfig = {
    flags: {
        connectZoomEnabled: true,
        connectMicrosoftTeamsEnabled: true,
        connectGoogleCalendarEnabled: true,
        connectOutlookEnabled: true,
        publicBookingEnabled: false
    }
}

// Functions

export function getBooleanEnvVariable(name: string): boolean | undefined {
    const value = process.env[name]
    return value ? value === 'true' : undefined
}

export function getEnvVariablesWithDefaults(env: LoadedRuntimeEnvConfig): RuntimeEnvConfig {
    return {
        flags: {
            connectZoomEnabled: env.flags.connectZoomEnabled ?? DEFAULT_ENV_VARIABLES.flags.connectZoomEnabled,
            connectMicrosoftTeamsEnabled: env.flags.connectMicrosoftTeamsEnabled ?? DEFAULT_ENV_VARIABLES.flags.connectMicrosoftTeamsEnabled,
            connectGoogleCalendarEnabled: env.flags.connectGoogleCalendarEnabled ?? DEFAULT_ENV_VARIABLES.flags.connectGoogleCalendarEnabled,
            connectOutlookEnabled: env.flags.connectOutlookEnabled ?? DEFAULT_ENV_VARIABLES.flags.connectOutlookEnabled,
            publicBookingEnabled: env.flags.publicBookingEnabled ?? DEFAULT_ENV_VARIABLES.flags.publicBookingEnabled
        }
    }
}
