'use client'

import { featuresList } from '@tebuto/layout/landing/features'
import { Heading, LandingSection } from './LandingComponents'
import Carousel from 'react-multi-carousel'
import Image from 'next/image'
import Link from 'next/link'
import { useIsScreenWidthBelow } from '@tebuto/functions/responsiveness'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Matomo } from '@tebuto/providers/Matomo'

export default function FeaturesOverview() {
    const isBelow768px = useIsScreenWidthBelow(768)

    return (
        <LandingSection className="overflow-visible">
            <Matomo.ScrollTrackingAnchor sectionName={'Funktionsübersicht'} />
            <Heading className="mb-10">Weniger Stress. Mehr Zeit.</Heading>
            <p className="text-landing mb-12">
                Entdecken Sie, wie unsere Software Ihre Arbeit als Psycholog:in, Psychotherapeut:in, Heilpraktiker:in für Psychotherapie oder Coach:in einfacher macht.
            </p>
            <Carousel
                ssr
                partialVisible
                swipeable
                draggable
                pauseOnHover
                arrows
                infinite
                customLeftArrow={isBelow768px ? <ChevronLeftIcon className="absolute left-0 stroke-2 bg-primary-600/20 p-2 rounded-full size-10 text-primary-600" /> : null}
                customRightArrow={isBelow768px ? <ChevronRightIcon className="absolute right-0 stroke-2 bg-primary-600/20 p-2 rounded-full size-10 text-primary-600" /> : null}
                customTransition="transform 1000ms ease-in-out"
                showDots={!isBelow768px}
                rewindWithAnimation
                responsive={{
                    large: {
                        breakpoint: { max: 4000, min: 768 },
                        items: 1,
                        partialVisibilityGutter: 100
                    },
                    small: {
                        breakpoint: { max: 768, min: 300 },
                        items: 1,
                        partialVisibilityGutter: 0
                    }
                }}
            >
                {featuresList.map((feature, index) => (
                    <div key={index} className={`relative max-h-[90vh] ${isBelow768px ? '' : 'rounded-xl overflow-hidden border m-4 mb-12'}`}>
                        <Matomo.ScrollTrackingAnchor sectionName={`Feature: ${feature.name}`} />
                        <Image
                            src={isBelow768px ? feature.mobileImage : feature.image}
                            alt={feature.name}
                            height={800}
                            width={1200}
                            className={`object-contain mx-auto ${isBelow768px ? 'max-w-[60vw]' : ''}`}
                            loading="eager"
                        />
                        <div
                            className={`${isBelow768px ? 'bg-gradient-to-b from-transparent from-0% to-15% to-primary-25 w-full pt-12' : 'absolute bottom-0 left-0 gap-y-4 w-full px-4 md:px-12 py-6 bg-primary-600/90 flex justify-between flex-wrap'}`}
                        >
                            <div>
                                <h3 className={`text-sm/4 ${isBelow768px ? 'text-xl font-semibold' : 'text-primary-50 font-bold'}`}>{feature.name}</h3>
                                <p className={`mt-2 md:text-lg tracking-tight ${isBelow768px ? 'text-base font-medium' : 'font-semibold text-white'}`}>{feature.subtitle}</p>
                            </div>
                            <Link className={`text-sm mt-auto block ${isBelow768px ? 'pt-4 underline' : 'text-white border py-1.5 px-3 rounded-lg'}`} href={feature.href}>
                                Mehr dazu
                            </Link>
                        </div>
                    </div>
                ))}
            </Carousel>
        </LandingSection>
    )
}
