/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
export type PublicEvent = {
    title: string;
    start: DateTime;
    end: string;
    location: PublicEvent.location;
    onlineMeetingProvider?: PublicEvent.onlineMeetingProvider;
    outageFeeEnabled: boolean;
    outageFeeHours?: number;
    outageFeePrice?: number;
    eventRuleId: number;
    eventCategoryId: number;
    color: string;
    price: number;
};
export namespace PublicEvent {
    export enum location {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
        NOT_FIXED = 'not-fixed',
    }
    export enum onlineMeetingProvider {
        ZOOM = 'zoom',
        MICROSOFT_TEAMS = 'microsoft-teams',
    }
}

