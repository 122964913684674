/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookPublicEventPayload } from '../models/BookPublicEventPayload';
import type { PublicEvent } from '../models/PublicEvent';
import type { PublicEventClaimPayload } from '../models/PublicEventClaimPayload';
import type { PublicEventClaimResponse } from '../models/PublicEventClaimResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param therapistUuid
     * @param fingerprint Fingerprint of the anonymous user. Required
     * @param end End date to filter for. Optional
     * @param start Start date to filter for. Optional
     * @param categories Comma-separated list of event category IDs to filter for. Optional
     * @returns PublicEvent
     * @throws ApiError
     */
    public eventsControllerGetPublicEvents(
        therapistUuid: string,
        fingerprint: string,
        end?: string,
        start?: string,
        categories?: string,
    ): CancelablePromise<Array<PublicEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{therapistUUID}',
            path: {
                'therapistUUID': therapistUuid,
            },
            query: {
                'fingerprint': fingerprint,
                'end': end,
                'start': start,
                'categories': categories,
            },
        });
    }
    /**
     * @param therapistUuid
     * @param requestBody
     * @returns PublicEventClaimResponse
     * @throws ApiError
     */
    public eventsControllerClaimPublicEvent(
        therapistUuid: string,
        requestBody: PublicEventClaimPayload,
    ): CancelablePromise<PublicEventClaimResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/{therapistUUID}/claim',
            path: {
                'therapistUUID': therapistUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public eventsControllerOptionsClaimPublicEvent(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/events/{therapistUUID}/claim',
        });
    }
    /**
     * @param therapistUuid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public eventsControllerUnclaimPublicEvent(
        therapistUuid: string,
        requestBody: PublicEventClaimPayload,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/{therapistUUID}/unclaim',
            path: {
                'therapistUUID': therapistUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public eventsControllerOptionsUnclaimPublicEvent(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/events/{therapistUUID}/unclaim',
        });
    }
    /**
     * @param therapistUuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public eventsControllerBookPublicEvent(
        therapistUuid: string,
        requestBody: BookPublicEventPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/{therapistUUID}/book',
            path: {
                'therapistUUID': therapistUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public eventsControllerOptionsBookPublicEvent(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/events/{therapistUUID}/book',
        });
    }
}
