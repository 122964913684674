import { Audience, Person } from 'schema-dts'

export const therapistAudience: Audience[] = [
    {
        '@type': 'MedicalAudience',
        audienceType: 'Psychotherapeuten'
    },
    {
        '@type': 'MedicalAudience',
        audienceType: 'Heilpraktiker für Psychotherapie'
    },
    {
        '@type': 'MedicalAudience',
        audienceType: 'Psychologen'
    },
    {
        '@type': 'MedicalAudience',
        audienceType: 'Psychiater'
    }
]

export const userAudience: Audience[] = [
    {
        '@type': 'Audience',
        audienceType: 'Klienten'
    },
    {
        '@type': 'Audience',
        audienceType: 'Patienten'
    }
]

export const generalAudience = therapistAudience.concat(userAudience)

export const tebutoTeam: Person[] = []

export const tebutoFounder: Person[] = [
    {
        '@type': 'Person',
        name: 'Jonas Scholl',
        worksFor: {
            '@type': 'Organization',
            name: 'Tebuto GmbH'
        },
        workLocation: {
            '@type': 'Place',
            address: {
                '@type': 'PostalAddress',
                addressLocality: 'Wiesloch',
                addressRegion: 'Baden-Württemberg',
                addressCountry: 'Germany'
            }
        },
        jobTitle: 'Mitgründer & Software Engineer',
        image: `${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/assets/jonas.webp`,
        sameAs: ['https://www.linkedin.com/in/jonas-scholl-4a1047205'],
        email: 'jonas@tebuto.de',
        birthDate: '2000-09-11',
        gender: 'Male',
        nationality: 'German',
        alumniOf: {
            '@type': 'EducationalOrganization',
            name: 'Duale Hochschule Baden-Württemberg (DHBW)'
        },
        knowsAbout: ['Software Engineering', 'Project Management', 'Web Development', 'DevOps'],
        knowsLanguage: ['German', 'English']
    },
    {
        '@type': 'Person',
        name: 'Yannic Schröer',
        worksFor: {
            '@type': 'Organization',
            name: 'Tebuto GmbH'
        },
        workLocation: {
            '@type': 'Place',
            address: {
                '@type': 'PostalAddress',
                addressLocality: 'Karlsruhe',
                addressRegion: 'Baden-Württemberg',
                addressCountry: 'Germany'
            }
        },
        jobTitle: 'Mitgründer & Software Engineer',
        image: `${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/assets/yannic.webp`,
        sameAs: ['https://www.linkedin.com/in/yannic-schroeer/'],
        email: 'yannic@tebuto.de',
        birthDate: '1992-03-23',
        gender: 'Male',
        nationality: 'German',
        alumniOf: {
            '@type': 'EducationalOrganization',
            name: 'Duale Hochschule Baden-Württemberg (DHBW)'
        },
        knowsAbout: ['Software Engineering', 'Project Management', 'Marketing'],
        knowsLanguage: ['German', 'English']
    }
]
