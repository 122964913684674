/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
export type BookPublicEventPayload = {
    eventRuleId: number;
    start: DateTime;
    end: DateTime;
    locationSelection?: BookPublicEventPayload.locationSelection;
    email: string;
    firstName: string;
    lastName: string;
    captcha: string;
    fingerprint: string;
};
export namespace BookPublicEventPayload {
    export enum locationSelection {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
    }
}

