/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateWidgetAllowedOriginsPayload } from '../models/UpdateWidgetAllowedOriginsPayload';
import type { WidgetAllowedOrigin } from '../models/WidgetAllowedOrigin';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WidgetService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get allowed origins for widget
     * Get allowed origins for widget
     * @param therapistId
     * @returns WidgetAllowedOrigin
     * @throws ApiError
     */
    public getAllowedOrigins(
        therapistId: number,
    ): CancelablePromise<Array<WidgetAllowedOrigin>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/widget/allowed-origins',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Update allowed origins for widget
     * Update allowed origins for widget
     * @param therapistId
     * @param requestBody
     * @returns WidgetAllowedOrigin
     * @throws ApiError
     */
    public updateAllowedOrigins(
        therapistId: number,
        requestBody: UpdateWidgetAllowedOriginsPayload,
    ): CancelablePromise<Array<WidgetAllowedOrigin>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/widget/allowed-origins',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
