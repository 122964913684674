import { extractTextFromReactNode } from '@tebuto/functions/extraction'
import { FAQPage, WithContext } from 'schema-dts'
import { therapistAudience } from './general'
import { JsonLdFaq } from './types'

export function JsonLdFAQ({ items, name }: { items: JsonLdFaq[]; name: string }): WithContext<FAQPage> {
    return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        name: name,
        mainEntity: items.map(item => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: extractTextFromReactNode(item.answer)
            }
        })),
        audience: therapistAudience
    }
}
