'use client'

import { ArchiveBoxIcon, DocumentTextIcon } from '@heroicons/react/24/solid'
import { useLoginStatus } from '@tebuto/functions'
import COMPONENT_TEXTS from '@tebuto/translations'
import { Menu, MenuEntry } from '../Menu'
import { featuresList } from './features'

export const Header = () => {
    const isLoggedIn = useLoginStatus()

    const leftItems: MenuEntry[] = [
        { name: COMPONENT_TEXTS.layout.landing.menu.home, href: '/' },
        {
            name: COMPONENT_TEXTS.layout.landing.menu.usage,
            href: '#',
            subMenu: {
                items: featuresList.map(feature => {
                    return { ...feature, description: feature.subtitle }
                }),
                cta: [
                    { name: 'Alle Funktionen', href: '/features', icon: ArchiveBoxIcon },
                    { name: 'Dokumentation', href: '/dokumentation', icon: DocumentTextIcon }
                ]
            }
        },
        { name: COMPONENT_TEXTS.layout.landing.menu.price, href: '/#preis' },
        { name: COMPONENT_TEXTS.layout.landing.menu.aboutUs, href: '/ueber-uns' },
        { name: COMPONENT_TEXTS.layout.landing.menu.roadmap, href: '/roadmap' },
        { name: COMPONENT_TEXTS.layout.landing.menu.blog, href: '/blog' }
    ]

    const rightItems: MenuEntry[] = []

    if (isLoggedIn) {
        rightItems.push({ name: COMPONENT_TEXTS.layout.landing.toApp, href: process.env.NEXT_PUBLIC_APP_BASE_URL as string, isAbsolute: true, highlight: true })
    } else {
        rightItems.push({ name: COMPONENT_TEXTS.layout.landing.menu.register, href: '/registrieren', highlight: true })
        rightItems.push({ name: COMPONENT_TEXTS.layout.landing.menu.login, href: '/login', id: 'login' })
    }

    const additionalMobileItems = [{ name: 'Dokumentation', href: '/dokumentation' }]

    return <Menu id="top" leftItems={leftItems} rightItems={rightItems} baseUrl={`${process.env.NEXT_PUBLIC_LANDING_BASE_URL}`} additionalMobileItems={additionalMobileItems} />
}
