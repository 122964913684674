/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
import type { ContactInfo } from './ContactInfo';
import type { Opening } from './Opening';
import type { PublicTherapistSettings } from './PublicTherapistSettings';
import type { TherapistCharacteristic } from './TherapistCharacteristic';
import type { TherapistInformationOption } from './TherapistInformationOption';
import type { TherapistLanguage } from './TherapistLanguage';
import type { TherapistLink } from './TherapistLink';
export type PublicTherapist = {
    address: Address;
    badges: Array<'online' | 'booking'>;
    slug: string;
    name: string;
    image: string | null;
    description: string | null;
    title: string | null;
    firstName: string | null;
    lastName: string | null;
    gender: PublicTherapist.gender | null;
    generated: boolean;
    acceptingNewClients: PublicTherapist.acceptingNewClients;
    uuid?: string;
    link: TherapistLink | null;
    settings: PublicTherapistSettings;
    informationSelections: Array<TherapistInformationOption>;
    openings: Array<Opening>;
    contactInfos: Array<ContactInfo>;
    languages: Array<TherapistLanguage>;
    characteristics: Array<TherapistCharacteristic>;
};
export namespace PublicTherapist {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
    export enum acceptingNewClients {
        NO = 'no',
        YES = 'yes',
        WAIT1TO2WEEKS = 'wait1To2Weeks',
        WAIT2TO4WEEKS = 'wait2To4Weeks',
        WAIT2MONTHS = 'wait2Months',
        WAIT3MONTHS = 'wait3Months',
        WAIT6MONTHS = 'wait6Months',
        WAIT1YEAR = 'wait1year',
    }
}

