import { Metadata } from 'next'
import { OpenGraph } from 'next/dist/lib/metadata/types/opengraph-types'
import { Twitter } from 'next/dist/lib/metadata/types/twitter-types'
import { JsonLdOrganization } from './organization'
import { JsonLDOrgnaization, JsonLdAdditionalImage, TebutoLandingMetadataProps } from './types'

const TebutoTwitterCard = ({
    title,
    description,
    additionalImages = []
}: { title: string; description: string; additionalImages?: JsonLdAdditionalImage[] }): { twitter: Twitter } => {
    const organization = JsonLdOrganization() as JsonLDOrgnaization

    return {
        twitter: {
            card: 'summary_large_image',
            // site: '@tebuto', // Official Twitter account of the organization
            // creator: '@tebuto', // Creator's Twitter handle
            title: title,
            description: description,
            images: [
                {
                    url: organization.image,
                    alt: 'Tebuto Logo und Slogan'
                },
                {
                    url: organization.logo,
                    alt: 'Tebuto Logo'
                }
            ].concat(additionalImages.map(image => ({ url: image.url, alt: image.alt })))
        }
    }
}

const TebutoOpenGraph = ({
    url,
    title,
    description,
    additionalImages = []
}: { url: string; title: string; description: string; additionalImages?: JsonLdAdditionalImage[] }): { openGraph: OpenGraph } => {
    const organization = JsonLdOrganization() as JsonLDOrgnaization

    return {
        openGraph: {
            type: 'website',
            locale: 'de_DE',
            url: url,
            countryName: 'Germany',
            determiner: 'auto',
            emails: [organization.email, ...organization.contactPoint.map(cp => cp.email)].filter(Boolean) as string[],
            phoneNumbers: [organization.telephone, ...organization.contactPoint.map(cp => cp.telephone)].filter(Boolean) as string[],
            siteName: organization.name,
            title: title,
            description: description,
            images: [
                {
                    url: organization.image,
                    width: 512,
                    height: 512,
                    alt: 'Tebuto Logo und Slogan'
                },
                {
                    url: organization.logo,
                    width: 512,
                    height: 512,
                    alt: 'Tebuto Logo'
                }
            ].concat(additionalImages)
            // Optional Videos for future reference
            // videos: [
            //     {
            //         url: 'https://example.com/video.mp4',
            //         type: 'video/mp4',
            //         width: 1920,
            //         height: 1080,
            //         alt: 'Video description'
            //     }
            // ],
            // Optional Audio if needed
            // audio: [
            //     {
            //         url: 'https://example.com/audio.mp3',
            //         type: 'audio/mpeg',
            //         alt: 'Audio description'
            //     }
            // ],
        }
    }
}

export function TebutoLandingMetadata({
    title,
    relativePath,
    description,
    additionalImages = [],
    additionalKeywords = [],
    disableTitleSuffix = false,
    noIndex = false
}: TebutoLandingMetadataProps): Metadata {
    const determinedTitle = disableTitleSuffix ? title : `${title} | Tebuto`

    return {
        ...TebutoOpenGraph({ title: determinedTitle, url: relativePath, description, additionalImages }),
        ...TebutoTwitterCard({ title: determinedTitle, description, additionalImages }),
        title: determinedTitle,
        robots: noIndex ? 'noindex, nofollow' : 'index, follow',
        description: description,
        metadataBase: new URL('https://tebuto.de'),
        icons: [
            { url: '/assets/favicon/favicon-16x16.png', sizes: '16x16', type: 'image/png' },
            { url: '/assets/favicon/favicon-32x32.png', sizes: '32x32', type: 'image/png' },
            { url: '/assets/favicon/favicon.svg', sizes: 'any', type: 'image/svg+xml' },
            { url: '/assets/favicon/android-chrome-512x512.png', sizes: '512x512', type: 'image/png' },
            { url: '/assets/favicon/mstile-70x70.png', sizes: '70x70', type: 'image/png' },
            { url: '/assets/favicon/mstile-144x144.png', sizes: '144x144', type: 'image/png' },
            { url: '/assets/favicon/mstile-150x150.png', sizes: '150x150', type: 'image/png' },
            { url: '/assets/favicon/mstile-310x150.png', sizes: '310x150', type: 'image/png' },
            { url: '/assets/favicon/mstile-310x310.png', sizes: '310x310', type: 'image/png' },
            { url: '/assets/favicon/safari-pinned-tab.svg', rel: 'mask-icon', color: '#5bbad5' }
        ],
        authors: [{ name: 'Jonas Scholl' }, { name: 'Yannic Schröer' }],
        category: 'Web Application',
        assets: additionalImages.map(image => image.url).concat(['/assets/tebuto.png']),
        abstract: description,
        alternates: {
            canonical: new URL(relativePath, 'https://tebuto.de').toString()
        },
        keywords: ['Online-Terminbuchung für Therapie, Beratung und Coaching'].concat(additionalKeywords)
    }
}
