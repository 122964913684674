import { IBlogPost } from '@tebuto/api/blog/client'
import { ServerSidePublicAPI } from '@tebuto/api/server-side'
import { Article, ProfessionalService, WithContext } from 'schema-dts'
import { therapistAudience, userAudience } from './general'
import { JsonLdTherapist } from './therapist'

export async function JsonLdArticle({ post }: { post: IBlogPost }): Promise<WithContext<Article>> {
    async function getAuthor(): Promise<ProfessionalService | { '@id': string }> {
        const tebuto = {
            '@id': 'https://tebuto.de/#organization'
        }

        if (post.authorId === 0) {
            return tebuto
        }

        const api = await ServerSidePublicAPI()
        const therapist = await api.therapists.getPublicTherapistById(post.authorId).catch(console.error)

        if (!therapist) {
            return tebuto
        }

        // TODO: User ID reference when the ID is properly implemented
        return JsonLdTherapist({ therapist })
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        '@id': `https://tebuto.de/blog/${post.category.slug}/${post.slug}`,
        publisher: { '@id': 'https://tebuto.de/#organization' },
        headline: post.title,
        image: [post.cover?.url ?? undefined],
        dateCreated: post.createdAt,
        datePublished: post.createdAt,
        dateModified: post.updatedAt,
        // wordCount: TODO: Implement word count
        // timeRequired: can be calculated by word count
        // text: post.content, // TODO: Implement text extraction
        url: `https://tebuto.de/blog/${post.category.slug}/${post.slug}`,
        sourceOrganization: {
            '@id': 'https://tebuto.de/#organization'
        },
        inLanguage: 'de',
        isAccessibleForFree: true,
        genre: post.category.slug === 'klienten' ? 'Psychologischer Ratgeber' : 'Psychologischer Fachartikel',
        copyrightYear: new Date().getFullYear(),
        description: post.excerpt,
        abstract: post.excerpt,
        // comment:
        // commentCount:
        accessMode: 'textual',
        audience: post.category.slug === 'klienten' ? userAudience : therapistAudience,
        author: await getAuthor()
    }
}
