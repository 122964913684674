/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlaceOpening } from './PlaceOpening';
export type ConfigurationPreconditionPayload = {
    name: string;
    streetAndNumber: string;
    additionalInformation?: string;
    cityName: string;
    zip: string;
    openings?: Array<PlaceOpening>;
    type: ConfigurationPreconditionPayload.type;
};
export namespace ConfigurationPreconditionPayload {
    export enum type {
        SELECT_PLAN = 'select-plan',
        CONFIGURATION = 'configuration',
        BILLING = 'billing',
        OPENINGS = 'openings',
        WAITLIST = 'waitlist',
    }
}

