import { BreadcrumbList, WithContext } from 'schema-dts'
import { JsonLdBreadcrumb } from './types'

export function JsonLdBreadcrumbList({ pages }: { pages: JsonLdBreadcrumb[] }): WithContext<BreadcrumbList> {
    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        name: 'Breadcrumbs',
        itemListElement: pages.map((page, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: page.name,
            item: `https://tebuto.de${page.href}`
        }))
    }
}
