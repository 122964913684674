'use client'
import { ClientsTebutoAPI, PublicTebutoAPI, TherapistsTebutoAPI } from '@tebuto/api/client-side'
import { ApiError as ClientsApiError } from '@tebuto/api/clients'
import { EventCategory } from '@tebuto/api/clients/models/EventCategory'
import { WhoAmIClient } from '@tebuto/api/clients/models/WhoAmIClient'
import { WhoAmITherapistUserLinking } from '@tebuto/api/clients/models/WhoAmITherapistUserLinking'
import { ApiError as PublicApiError } from '@tebuto/api/public'
import { ApiError as TherapistsApiError } from '@tebuto/api/therapists'
import { Contract } from '@tebuto/api/therapists/models/Contract'
import { EventRule } from '@tebuto/api/therapists/models/EventRule'
import { WhoAmI } from '@tebuto/api/therapists/models/WhoAmI'
import { CurrentClientContext } from '@tebuto/guards/CurrentClient'
import { CurrentUserContext } from '@tebuto/guards/CurrentUser'
import { CurrentLoginStatusContext } from '@tebuto/guards/LoginStatus'
import { SelectedTherapistIdContext } from '@tebuto/guards/TherapistSelection'
import { MutableRefObject, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useSWR, { SWRResponse } from 'swr'
import { BareFetcher, FetcherResponse, Key, SWRConfiguration, useSWRConfig } from 'swr/_internal'
import useSWRMutation, { MutationFetcher, SWRMutationConfiguration, SWRMutationResponse } from 'swr/mutation'
import { ERROR_MESSAGES, resolveErrorMessage } from './api'

export function useCurrentClient(): WhoAmIClient {
    const whoAmI = useContext(CurrentClientContext)
    if (!whoAmI) {
        throw new Error('useCurrentUser must be used within the CurrentUserGuard')
    }
    return whoAmI
}

export function useCurrentUser(): WhoAmI {
    const whoAmI = useContext(CurrentUserContext)
    if (!whoAmI) {
        throw new Error('useCurrentUser must be used within the CurrentUserGuard')
    }
    return whoAmI
}

export function useLoginStatus(): boolean {
    const currentLoginStatus = useContext(CurrentLoginStatusContext)
    return currentLoginStatus
}

export function useSelectedTherapist() {
    const selectedTherapistId = useSelectedTherapistId()
    return useTherapistsApiRequest(['therapist', selectedTherapistId], (api, [_, therapistId]) => api.therapists.getTherapist(therapistId))
}

export function useSelectedTherapistId(): number {
    const selectedTherapistId = useContext(SelectedTherapistIdContext)
    if (selectedTherapistId === null) {
        throw new Error('useSelectedTherapistId must be used within the TherapistSelectionGuard')
    }
    return selectedTherapistId
}

export function useSelectedTherapistRole(): WhoAmITherapistUserLinking.role {
    const therapistId = useSelectedTherapistId()
    const currentUser = useCurrentUser()
    const role = currentUser.therapists.find(linking => linking.therapist.id === therapistId)?.role
    if (!role) {
        throw new Error('Could not find selected therapist in user context')
    }
    return role
}

export function useSelectedPublicTherapist() {
    const { slug } = useSelectedClientWhoAmITherapist()
    return usePublicApiRequest(['public-therapist', slug], (api, [_, slug]) => api.therapists.getPublicTherapist(slug))
}

export function useSelectedClientTherapist() {
    const therapistId = useSelectedTherapistId()
    const { data: therapist, showLoading, error, mutate } = useClientsApiRequest(['therapist', therapistId], api => api.therapists.getTherapist(therapistId))
    return { therapist, showLoading, error, mutate }
}

export function useSelectedClientWhoAmITherapist() {
    const selectedTherapistId = useSelectedTherapistId()
    const currentClient = useCurrentClient()
    const selectedTherapist = currentClient.therapists.find(therapist => therapist.id === selectedTherapistId)
    if (!selectedTherapist) {
        throw new Error('Could not find selected therapist in client context')
    }
    return selectedTherapist
}

export function useTherapistPlan(): Contract.plan | null {
    const currentUser = useCurrentUser()
    const selectedTherapistId = useSelectedTherapistId()
    return currentUser.therapists.find(linking => linking.therapist.id === selectedTherapistId)?.therapist.contract?.plan || null
}

export function useCategory(categoryId: number): EventCategory | undefined {
    const therapistId = useSelectedTherapistId()
    const { data: categories } = useTherapistsApiRequest(['categories', therapistId], api => api.eventCategories.getAllEventCategories(therapistId))
    return categories?.find(category => category.id === categoryId)
}

export function useRule(ruleId: number): EventRule | undefined {
    const therapistId = useSelectedTherapistId()
    const { data: rules } = useTherapistsApiRequest(['rules', therapistId], api => api.eventRules.getAllEventRules(therapistId))
    return rules?.find(rule => rule.id === ruleId)
}

export function useCategoryMapping() {
    const therapistId = useSelectedTherapistId()
    const [categoryMapping, setCategoryMapping] = useState<{ [key: number]: EventCategory }>({})
    const {
        data: categories,
        showLoading,
        error,
        mutate
    } = useClientsApiRequest(['categories', therapistId], api => api.eventCategories.getAllEventCategoriesForClients(therapistId))

    useEffect(() => {
        if (categories !== undefined) {
            setCategoryMapping(
                categories.reduce(
                    (acc, category) => {
                        acc[category.id] = category
                        return acc
                    },
                    {} as { [key: number]: EventCategory }
                )
            )
        }
    }, [categories])

    return { data: categoryMapping, showLoading, error, mutate }
}

// API Queries

function useApiRequest<
    API extends typeof PublicTebutoAPI | typeof TherapistsTebutoAPI | typeof ClientsTebutoAPI,
    Data,
    SWRKey extends Key = Key,
    SWROptions extends SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined = SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
>(api: API, key: SWRKey, request: (api: API, key: SWRKey) => FetcherResponse<Data>, config?: SWROptions & AdditionalRequestConfig): SWRResponse<Data> & { showLoading: boolean } {
    const [isLoading, setIsLoading] = useState(false)

    const response = useSWR(key, (key: SWRKey) => request(api, key), {
        onError: defaultErrorHandler,
        onLoadingSlow: () => setIsLoading(true),
        ...config
    })

    return {
        ...response,
        showLoading: isLoading && response.data === undefined && !response.error
    }
}

export function usePublicApiRequest<
    Data,
    SWRKey extends Key = Key,
    SWROptions extends SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined = SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
>(
    key: SWRKey,
    request: (api: typeof PublicTebutoAPI, key: SWRKey) => FetcherResponse<Data>,
    config?: SWROptions & AdditionalRequestConfig
): SWRResponse<Data> & { showLoading: boolean } {
    return useApiRequest(PublicTebutoAPI, key, request, config)
}

export function useTherapistsApiRequest<
    Data,
    SWRKey extends Key = Key,
    SWROptions extends SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined = SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
>(
    key: SWRKey,
    request: (api: typeof TherapistsTebutoAPI, key: SWRKey) => FetcherResponse<Data>,
    config?: SWROptions & AdditionalRequestConfig
): SWRResponse<Data> & { showLoading: boolean } {
    return useApiRequest(TherapistsTebutoAPI, key, request, config)
}

export function useClientsApiRequest<
    Data,
    SWRKey extends Key = Key,
    SWROptions extends SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined = SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
>(
    key: SWRKey,
    request: (api: typeof ClientsTebutoAPI, key: SWRKey) => FetcherResponse<Data>,
    config?: SWROptions & AdditionalRequestConfig
): SWRResponse<Data> & { showLoading: boolean } {
    return useApiRequest(ClientsTebutoAPI, key, request, config)
}

// API Mutations

function useApiMutation<API extends typeof PublicTebutoAPI | typeof TherapistsTebutoAPI | typeof ClientsTebutoAPI, Data, ExtraArg = never, SWRMutationKey extends Key = Key>(
    api: API,
    key: SWRMutationKey,
    request: (api: API, options: ExtraArg, key: SWRMutationKey) => FetcherResponse<Data>,
    config?: SWRMutationConfiguration<Data, Error, SWRMutationKey, ExtraArg, Data> & AdditionalMutationRequestConfig
): SWRMutationResponse<Data, any, SWRMutationKey, ExtraArg> {
    const { mutate } = useSWRConfig()
    return useSWRMutation(key, ((key: SWRMutationKey, options: { arg: ExtraArg }) => request(api, options?.arg, key)) as MutationFetcher<Data, SWRMutationKey, ExtraArg>, {
        onError: defaultErrorHandler,
        ...config,
        onSuccess: (data, key, responseConfig) => {
            if (config?.onSuccess) {
                config.onSuccess(data, key, responseConfig)
            } else {
                mutate(key, data, responseConfig)
            }
            if (config?.successMessage !== null) {
                toast.success(config?.successMessage || 'Gespeichert')
            }
        }
    })
}

export function usePublicApiMutation<Data, ExtraArg = never, SWRMutationKey extends Key = Key>(
    key: SWRMutationKey,
    request: (api: typeof PublicTebutoAPI, option: ExtraArg, key: SWRMutationKey) => FetcherResponse<Data>,
    config?: SWRMutationConfiguration<Data, Error, SWRMutationKey, ExtraArg, Data> & AdditionalMutationRequestConfig
): SWRMutationResponse<Data, any, SWRMutationKey, ExtraArg> {
    return useApiMutation(PublicTebutoAPI, key, request, config)
}

export function useTherapistsApiMutation<Data, ExtraArg = never, SWRMutationKey extends Key = Key>(
    key: SWRMutationKey,
    request: (api: typeof TherapistsTebutoAPI, options: ExtraArg, key: SWRMutationKey) => FetcherResponse<Data>,
    config?: SWRMutationConfiguration<Data, Error, SWRMutationKey, ExtraArg, Data> & AdditionalMutationRequestConfig
): SWRMutationResponse<Data, any, SWRMutationKey, ExtraArg> {
    return useApiMutation(TherapistsTebutoAPI, key, request, config)
}

export function useClientsApiMutation<Data, ExtraArg = never, SWRMutationKey extends Key = Key>(
    key: SWRMutationKey,
    request: (api: typeof ClientsTebutoAPI, options: ExtraArg, key: SWRMutationKey) => FetcherResponse<Data>,
    config?: SWRMutationConfiguration<Data, Error, SWRMutationKey, ExtraArg, Data> & AdditionalMutationRequestConfig
): SWRMutationResponse<Data, any, SWRMutationKey, ExtraArg> {
    return useApiMutation(ClientsTebutoAPI, key, request, config)
}

export function defaultErrorHandler(err: any, key: string, config: any) {
    if (config.handleUnauthorized !== false) {
        if (err instanceof TherapistsApiError && err.status === 401) {
            window.location.href = `${process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL}?returnTo=${window.location.href}`
            return
        }

        if (err instanceof ClientsApiError && err.status === 401) {
            window.location.href = '/login'
            return
        }
    }

    let errorMessage = ERROR_MESSAGES.Unknown
    if (err instanceof TherapistsApiError || err instanceof ClientsApiError || err instanceof PublicApiError) {
        errorMessage = resolveErrorMessage(err.body)
    }

    toast.error(errorMessage, { toastId: errorMessage })
}

interface AdditionalRequestConfig {
    handleUnauthorized?: boolean
}

interface AdditionalMutationRequestConfig extends AdditionalRequestConfig {
    successMessage?: string | null
}

export function useComponentIsVisible(elementRef: MutableRefObject<HTMLElement | null>, threshold = 0.2): boolean {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting)
            },
            {
                root: null, // viewport
                rootMargin: '0px', // no margin
                threshold // % of target visible
            }
        )

        if (elementRef.current) {
            observer.observe(elementRef.current)
        }

        // Clean up the observer
        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current)
            }
        }
    }, [])

    return isVisible
}
