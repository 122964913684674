'use client'

import useRouter from '@tebuto/guards/leave-page/useRouter'
import { ButtonSpinner } from '@tebuto/layout/Spinner'

type ButtonStyle = 'default' | 'large' | 'small'

export interface IButton {
    href?: string
    type?: 'button' | 'submit' | 'reset' | undefined
    outline?: boolean
    className?: string
    disabled?: boolean
    buttonStyle?: ButtonStyle
    isLoading?: boolean
    color?: ButtonColor
}

type ButtonColor = 'primary' | 'red' | 'blue' | 'orange' | 'secondary' | 'white'

function styleClasses(style: ButtonStyle) {
    switch (style) {
        case 'large':
            return 'sm:text-lg text-base sm:px-6 sm:py-3 px-3 py-1.5 sm:h-[3.4375rem] h-[3rem]'
        case 'small':
            return 'sm:text-xs text-xxs sm:px-2 sm:py-1 px-1 py-0.5 sm:h-[1.875rem] h-[1.5rem]'
        default:
            return 'sm:text-sm text-xs sm:px-4 sm:py-2 px-2 py-1 sm:h-[2.25rem] h-[2rem]'
    }
}

function getColorCSS(color: ButtonColor, outline: boolean) {
    switch (color) {
        case 'primary':
            return outline ? 'text-primary-600 border-primary-600 hover:bg-primary-50 border' : 'text-white bg-primary-600 hover:bg-primary-500'
        case 'red':
            return outline ? 'text-red-600 border-red-600 hover:bg-red-50 border' : 'text-white bg-red-600 hover:bg-red-500'
        case 'blue':
            return outline ? 'text-sky-600 border-sky-600 hover:bg-sky-50 border' : 'text-white bg-sky-600 hover:bg-sky-500'
        case 'orange':
            return outline ? 'text-orange-600 border-orange-600 hover:bg-orange-50 border' : 'text-white bg-orange-600 hover:bg-orange-500'
        case 'secondary':
            return outline ? 'text-gray-500 border-gray-500 hover:bg-gray-200 hover:text-gray-700 border' : 'text-white bg-gray-500 hover:bg-gray-500'
        case 'white':
            return outline ? 'text-white border-white hover:bg-white hover:text-primary-600 border' : 'text-white bg-white hover:bg-white hover:text-primary-600'
    }
}

export function Button({
    children,
    href,
    type = 'button',
    outline = false,
    onClick,
    className,
    disabled = false,
    buttonStyle = 'default',
    isLoading = false,
    color = 'primary',
    ...rest
}: IButton & React.HTMLProps<HTMLButtonElement>) {
    let defaultCSS =
        'w-full rounded font-medium shadow-sm flex justify-center items-center px-4 whitespace-nowrap transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-opacity-50'
    const colors = getColorCSS(color, outline)

    defaultCSS += ` ${colors}`
    defaultCSS += ` ${styleClasses(buttonStyle)}`

    if (disabled) {
        defaultCSS += ' cursor-not-allowed opacity-50'
    }

    if (className) {
        defaultCSS += ` ${className}`
    }

    function Button() {
        return (
            <button
                {...rest}
                onMouseDown={event => {
                    event.preventDefault()
                }}
                className={defaultCSS}
                onClick={onClick}
                type={type}
                disabled={disabled || isLoading}
            >
                {isLoading ? <ButtonSpinner /> : children}
            </button>
        )
    }

    if (href) {
        if (onClick) {
            throw new Error('Button cannot have both href and onClick')
        }

        const router = useRouter()

        return (
            <button
                {...rest}
                onMouseDown={event => {
                    event.preventDefault()
                }}
                className={defaultCSS}
                onClick={() => router.push(href)}
                type={type}
                disabled={disabled || isLoading}
            >
                {isLoading ? <ButtonSpinner /> : children}
            </button>
        )
    }

    return <Button />
}
