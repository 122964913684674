/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingWithoutEvent } from './BookingWithoutEvent';
export type BookedEvent = {
    id: number;
    status: BookedEvent.status;
    notice: string | null;
    booking?: BookingWithoutEvent | null;
};
export namespace BookedEvent {
    export enum status {
        OPEN = 'open',
        BOOKED = 'booked',
        APPROVED = 'approved',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
        OUTAGE = 'outage',
        SKIPPED = 'skipped',
    }
}

