export const ERROR_MESSAGES = {
    // default
    BadRequest: 'Ungültige Anfrage',
    Unauthorized: 'Nicht angemeldet',
    Forbidden: 'Das dürfen Sie nicht tun',
    NotFound: 'Nicht gefunden',
    MethodNotAllowed: 'Anfrage nicht erlaubt',
    Conflict: 'Das gibt es schon',
    TooManyRequests: 'Zu viele Anfragen',
    InternalServerError: 'Ups, da ist leider etwas schiefgelaufen. Bitte versuchen Sie es später erneut oder kontaktieren den Support.',
    Unknown: 'Unbekannter Fehler',

    // custom
    AccessDenied: 'Zugriff verweigert',
    ScopesMissing: 'Benötigte Berechtigungen wurden nicht erteilt',
    PersonalMicrosoftAccountsNotSupported: 'Persönliche Microsoft-Konten werden nicht unterstützt. Bitte verwenden Sie ein Geschäfts- oder Schulkonto.',
    InvalidOldPassword: 'Altes Passwort ist ungültig',
    InvalidPasswordMinSpecialChars: 'Passwort muss mindestens ein Sonderzeichen enthalten',
    InvalidPasswordMinUpperCaseChars: 'Passwort muss mindestens einen Großbuchstaben enthalten',
    InvalidPasswordMinDigits: 'Passwort muss mindestens eine Zahl enthalten',
    InvalidPasswordMinLength: 'Passwort muss mindestens 10 Zeichen lang sein',
    InvalidPasswordMaxLength: 'Passwort darf maximal 256 Zeichen lang sein',
    InvalidDateTime: 'Ungültiges Datum oder Uhrzeit',
    InvalidDate: 'Ungültiges Datum',
    InvalidTime: 'Ungültige Uhrzeit',
    EmailAlreadyInUse: 'Diese E-Mail-Adresse wird bereits verwendet',
    EmailNotChanged: 'Die E-Mail-Adresse wurde nicht geändert',
    InvalidNotification: 'Ungültige Benachrichtigung',
    InvalidStartDate: 'Ungültiges Startdatum',
    InvalidEndDate: 'Ungültiges Enddatum',
    ZipOrCityRequired: 'Postleitzahl oder Stadt ist erforderlich',
    InvalidCaptcha: 'Ungültige CAPTCHA-Eingabe',
    SlugAlreadyInUse: 'Slug wird bereits verwendet',
    InvalidSlug: 'Ungültiger Slug',
    InvalidStatus: 'Ungültiger Status',
    InvalidOutageFeeApplies: 'Ungültige Angabe für Ausfallhonorar',
    EndTimeMustBeAfterStartTime: 'Endzeit muss nach der Startzeit liegen',
    OnlyTwoOpeningSlotsPerDay: 'Es sind nur zwei Öffnungsslots pro Tag erlaubt',
    OpeningsOverlapping: 'Öffnungszeiten überschneiden sich',
    PreconditionAlreadyFulfilled: 'Voraussetzung bereits erfüllt',
    InvalidPayloadForSelectPlanFulfillment: 'Ungültige Daten für das Auswählen des Tarifs',
    InvalidPayloadForConfigurationFulfillment: 'Ungültige Daten für die Konfiguration',
    NoPaymentMethodFound: 'Keine Zahlungsmethode gefunden',
    NoActiveSubscriptionFound: 'Kein aktives Abonnement gefunden',
    CalendarProviderIsRequired: 'Kalenderanbieter ist erforderlich',
    MeetingProviderIsRequired: 'Meeting-Anbieter ist erforderlich',
    CannotChangePasswordForSocialLoginUsers: 'Passwort kann für Social-Login-Benutzer nicht geändert werden',
    InvalidAccountProvider: 'Ungültiger Anbieter',
    GoogleAccountNotFound: 'Google-Konto nicht gefunden',
    InvalidState: 'Ungültiger Zustand',
    InvalidCode: 'Ungültiger Code',
    AccountAlreadyConnected: 'Konto bereits verbunden',
    InvalidResponseFromMicrosoftTeams: 'Ungültige Antwort von Microsoft Teams',
    InvalidResponseFromZoom: 'Ungültige Antwort von Zoom',
    InvalidDateFormatForZoomEvent: 'Ungültiges Datumsformat für Zoom-Event',
    LocationSelectionIsRequired: 'Standortauswahl ist erforderlich',
    CannotBookAnEventThatIsAlreadyBooked: 'Termin kann nicht gebucht werden, da er bereits gebucht ist',
    CannotBookAnEventThatStartsInThePast: 'Termin kann nicht gebucht werden, da er in der Vergangenheit beginnt',
    CannotBookAnEventThatIsTooFarInTheFuture: 'Termin kann nicht gebucht werden, da er zu weit in der Zukunft liegt',
    CannotBookAnEventThatConflictsWithAnotherEvent: 'Termin kann nicht gebucht werden, da er mit einem anderen Termin kollidiert',
    CannotApproveBookingThatIsNotPending: 'Buchung kann nicht genehmigt werden, da sie nicht aussteht',
    CannotApproveBookingThatStartsInThePast: 'Buchung kann nicht genehmigt werden, da sie in der Vergangenheit beginnt',
    CannotRejectBookingThatIsNotPending: 'Buchung kann nicht abgelehnt werden, da sie nicht aussteht',
    CannotRejectBookingThatStartsInThePast: 'Buchung kann nicht abgelehnt werden, da sie in der Vergangenheit beginnt',
    CannotCancelBookingThatIsNotApproved: 'Buchung kann nicht storniert werden, da sie nicht genehmigt wurde',
    CannotCancelBookingThatIsNotBookedOrApproved: 'Buchung kann nicht storniert werden, da sie nicht gebucht oder genehmigt ist',
    CannotCancelBookingThatStartsInThePast: 'Buchung kann nicht storniert werden, da sie in der Vergangenheit beginnt',
    CannotMarkBookingAsNoShowThatIsNotApproved: 'Buchung kann nicht als No-Show markiert werden, da sie nicht genehmigt wurde',
    OutageFeeAppliesMustBeYesOrNo: 'Ausfallhonorar muss ja oder nein sein',
    OutageFeeHasAlreadyBeenApplied: 'Ausfallhonorar wurde bereits angewendet',
    CannotApplyOutageFeeToBookingThatIsNotApproved: 'Ausfallhonorar kann nicht auf eine nicht genehmigte Buchung angewendet werden',
    CannotApplyOutageFeeToBookingThatIsNotStartedAndOutage: 'Ausfallhonorar kann nicht auf eine Buchung angewendet werden, die nicht gestartet ist und nicht ausgefallen ist',
    ClientNotFound: 'Client nicht gefunden',
    InvalidInvitationLink: 'Ungültiger Einladungslink',
    NotLinkedToTherapist: 'Nicht mit dem Therapeuten verknüpft',
    TherapistNotFound: 'Therapeut nicht gefunden',
    OwnerNotFound: 'Inhaber nicht gefunden',
    CityNotFound: 'Stadt nicht gefunden',
    FeatureNotFound: 'Funktion nicht gefunden',
    CommentNotFound: 'Kommentar nicht gefunden',
    AddressNotFound: 'Adresse nicht gefunden',
    CalendarNotFound: 'Kalender nicht gefunden',
    ThirdPartyAccountNotFound: 'Drittanbieter-Konto nicht gefunden',
    InvitedClientNotFound: 'Eingeladener Klient nicht gefunden',
    EventCategoryNotFound: 'Ereigniskategorie nicht gefunden',
    EventRuleNotFound: 'Termin(serie) nicht gefunden',
    EventNotFound: 'Ereignis nicht gefunden',
    BookingNotFound: 'Buchung nicht gefunden',
    NotificationNotFound: 'Benachrichtigung nicht gefunden',
    PreconditionNotFound: 'Voraussetzung nicht gefunden',
    SettingsNotFound: 'Einstellungen nicht gefunden',
    UserNotFound: 'Benutzer nicht gefunden',
    LinkNotFoundOrExpired: 'Link nicht gefunden oder abgelaufen',
    NotAllowedToAccessThisClient: 'Sie haben keine Berechtigung, auf diesen Klienten zuzugreifen',
    NotAllowedToAccessThisTherapist: 'Sie haben keine Berechtigung, auf diesen Therapeuten zuzugreifen',
    NotAllowedToAccessThisUser: 'Sie haben keine Berechtigung, auf diesen Benutzer zuzugreifen',
    NotAllowedToPerformThisAction: 'Sie dürfen diese Aktion nicht ausführen',
    NotAllowedToPerformThisActionForTheTherapist: 'Sie dürfen diese Aktion für den Therapeuten nicht ausführen',
    AppointmentBookingPlanRequired: 'Tarif für Terminbuchung erforderlich',
    AlreadyVotedForFeature: 'Sie haben bereits für diese Funktion abgestimmt',
    CharacteristicAlreadyExists: 'Merkmal existiert bereits',
    AlreadySubscribedToPlan: 'Dieser Tarif ist bereits abonniert',
    InvalidFileType: 'Ungültiger Dateityp',
    CouldNotConnectToZoom: 'Verbindung zu Zoom konnte nicht hergestellt werden',
    EndOfRuleCannotBeBeforeStartOfRule: 'Das Ende des Termins darf nicht vor dem Anfang der Termins liegen',
    RuleConflict: 'Der Serientermin überschneiden sich mit {conflict}',
    SomeClientsAreAlreadyInvited: 'Einige Klienten sind bereits eingeladen',
    InvalidCharacteristicCategory: 'Ungültige Kategorie für Merkmal',
    FileNotFound: 'Datei nicht gefunden',
    CharacteristicNotFound: 'Merkmal nicht gefunden',
    LoginCodeRequestRateLimited:
        'Sie haben bereits vor kurzem einen Code angefordert. Bitte versuchen Sie es später erneut, ein Code kann nur einmal pro Minute angefordert werden.',
    InvalidSignatureVersion: 'Ungültige Signaturversion',
    InvalidSignature: 'Ungültige Signatur',
    CalendarExportUrlAlreadyExists: 'Kalenderexport-URL existiert bereits',
    EventConflict: 'An der Stelle gibt es bereits einen Termin',
    CannotUpdateAnEventThatHasEnded: 'Termin kann nicht aktualisiert werden, da er bereits beendet ist',
    EventIsNotAvailableForBooking: 'Dieser Termin ist leider nicht mehr verfügbar.',
    InvalidDomain: 'Ungültige Domain'
}

export function resolveErrorMessage(body: Record<string, string>): string {
    if (Object.keys(ERROR_MESSAGES).includes(body.type)) {
        let message = ERROR_MESSAGES[body.type as keyof typeof ERROR_MESSAGES]

        const matches = message.match(/{[^}]+}/g)
        for (const match of matches || []) {
            const key = match.slice(1, -1)
            let replacement = ''
            if (Object.hasOwn(body, key)) {
                replacement = body[key]
            }
            message = message.replace(match, replacement)
        }

        return message
    }

    console.error(`Unknown error type: ${body.type}`)
    return ERROR_MESSAGES.Unknown
}
