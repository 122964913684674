import COMPONENT_TEXTS from '@tebuto/translations'

export default function Footer() {
    const navigation = [
        { name: COMPONENT_TEXTS.layout.client.landing, href: process.env.NEXT_PUBLIC_LANDING_BASE_URL },
        { name: COMPONENT_TEXTS.layout.client.imprint, href: `${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/impressum` },
        { name: COMPONENT_TEXTS.layout.client.privacy, href: `${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/datenschutzerklaerung` }
    ]

    return (
        <footer className="w-full mx-auto">
            <div className="mx-auto max-w-5xl border-t py-6 px-4 overflow-hidden sm:px-6 lg:flex">
                <p className="text-left text-xs text-gray-400 pt-1 place-self-start w-max mr-8 hidden lg:block">&copy; {COMPONENT_TEXTS.layout.copyright}</p>
                <nav className="lg:flex ml-auto" aria-label="Footer">
                    {navigation.map(item => (
                        <div key={item.name} className="lg:px-5">
                            <a href={item.href} className="text-xs text-gray-400 hover:text-primary-500">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <p className="text-left text-xs text-gray-400 pt-1 place-self-start w-max mt-4 lg:hidden">&copy; {COMPONENT_TEXTS.layout.copyright}</p>
            </div>
        </footer>
    )
}

export function LoginFooter() {
    const navigation = [
        { name: COMPONENT_TEXTS.layout.client.landing, href: process.env.NEXT_PUBLIC_LANDING_BASE_URL },
        { name: COMPONENT_TEXTS.layout.client.imprint, href: `${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/impressum` },
        { name: COMPONENT_TEXTS.layout.client.privacy, href: `${process.env.NEXT_PUBLIC_LANDING_BASE_URL}/datenschutzerklaerung` }
    ]

    return (
        <footer className="w-full mx-auto">
            <div className="mx-auto max-w-5xl py-6 px-4 overflow-hidden sm:px-6 lg:flex lg:border-t border-primary-400">
                <p className="text-left text-xs text-primary-300 pt-1 place-self-start w-max mr-8 hidden lg:block">&copy; {COMPONENT_TEXTS.layout.copyright}</p>
                <nav className="lg:flex ml-auto" aria-label="Footer">
                    {navigation.map(item => (
                        <div key={item.name} className="lg:px-5">
                            <a href={item.href} className="text-xs text-primary-200 hover:text-primary-300">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <p className="text-left text-xs text-gray-300 pt-1 place-self-start w-max mt-4 lg:hidden">&copy; {COMPONENT_TEXTS.layout.copyright}</p>
            </div>
        </footer>
    )
}
