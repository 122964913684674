import { DateTime } from 'luxon'
import { WebApplication, WithContext } from 'schema-dts'
import { generalAudience } from './general'

export function JsonLdWebApp(): WithContext<WebApplication> {
    return {
        '@context': 'https://schema.org',
        '@id': 'https://tebuto.de/#app',
        '@type': 'WebApplication',
        name: 'Tebuto - Online-Terminbuchung',
        applicationCategory: 'HealthApplication',
        operatingSystem: 'All',
        browserRequirements: 'Aktueller Webbrowser mit HTML5-Unterstützung',
        description:
            'Tebuto ist eine Webanwendung, die bei Therapie, Beratung und Coaching dabei unterstützt die Terminplanung zu optimieren und sich auf das Wesentliche zu konzentrieren: die Klient:innen.',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '5',
            reviewCount: 1,
            bestRating: '5',
            worstRating: '1'
        },
        offers: [
            {
                '@type': 'AggregateOffer',
                name: 'Online-Terminbuchung',
                priceCurrency: 'EUR',
                lowPrice: 69,
                description:
                    'Die Online-Terminbuchung für Therapie, Beratung und Coaching ermöglicht es, Termine einfach und schnell online zu vereinbaren. Die Software ist speziell auf die Bedürfnisse von Therapeuten zugeschnitten und bietet eine Vielzahl von Funktionen, die den Arbeitsalltag von psychologischen Fachkräften erleichtern.',
                priceSpecification: {
                    '@type': 'UnitPriceSpecification',
                    price: 69,
                    priceCurrency: 'EUR',
                    billingDuration: 'P1M',
                    description: 'Monatliche Kosten für die Online-Terminbuchung'
                }
            }
        ],
        author: {
            '@id': 'https://tebuto.de/#organization'
        },
        mainEntityOfPage: [
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/klientenportal',
                name: 'Klientenportal'
            },
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/termin-management',
                name: 'Termin-Management'
            },
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/online-meetings',
                name: 'Online-Meetings'
            },
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/ausfallhonorar',
                name: 'Ausfallhonorare'
            },
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/kalender-integration',
                name: 'Kalender-Integration'
            },
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/automatisierte-kommunikation',
                name: 'Automatisierte Kommunikation'
            },
            {
                '@type': 'WebPage',
                url: 'https://tebuto.de/features/datenschutz-und-dsgvo',
                name: 'Datenschutz und DSGVO'
            }
        ],
        audience: generalAudience,
        datePublished: '2024-12-17',
        dateModified: DateTime.now().toFormat('yyyy-mm-dd'), // Potentially automate this
        softwareVersion: '1.0.0', // TODO: Use request to https://api.tebuto.de/health
        keywords: [
            'Online-Terminbuchung',
            'Online-Terminbuchung für Psychologen',
            'Online-Terminbuchung für Psychotherapeuten',
            'Online-Terminbuchung für Psychiater',
            'Online-Terminbuchung für Heilpraktiker für Psychotherapie',
            'Online-Terminbuchung für Coaches',
            'Online-Terminbuchung für Berater',
            'Psychotherapie'
        ],
        isAccessibleForFree: true,
        potentialAction: {
            '@type': 'RegisterAction',
            target: 'https://tebuto.de/registrieren',
            name: 'Registrieren für Tebuto'
        }
    }
}
