'use client'

import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

export interface SubMenuEntryProps {
    name: string
    description: string
    href: string
    icon: any
}
export interface SubMenuCTAProps {
    name: string
    href: string
    icon: any
}

export interface SubMenuProps {
    items: SubMenuEntryProps[]
    cta?: SubMenuCTAProps[]
}

export interface MenuEntry {
    name: string
    href?: string
    onClick?: () => void
    subMenu?: SubMenuProps
    isAbsolute?: boolean
    id?: string
    highlight?: boolean
}

export interface IMenu {
    leftItems: MenuEntry[]
    rightItems: MenuEntry[]
    additionalMobileItems?: MenuEntry[]
    baseUrl: string
    id?: string
}

export function Menu({ leftItems, rightItems, baseUrl, id, additionalMobileItems }: IMenu) {
    const activeRoute = usePathname()

    function SubMenu({ subMenu, name }: { subMenu: SubMenuProps; name: string }) {
        return (
            <Popover className="relative inline">
                <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6">
                    <span className={'px-2 py-2 font-medium text-white rounded-md text-[0.875rem] dark:!text-primary-200'}>{name}</span>
                    <ChevronDownIcon className={'h-5 w-5 text-white mr-2 transition-transform transform duration-300 ui-open:rotate-180'} aria-hidden="true" />
                </PopoverButton>
                <Transition
                    enter="transition ease-out duration-1000"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-0 mt-4 flex w-screen max-w-max -translate-x-1/4">
                        <div className="w-screen max-w-5xl flex-auto overflow-hidden rounded-3xl bg-gradient-primary-decent border border-primary-800 text-sm leading-6 shadow-xl">
                            <div className="p-4 grid grid-cols-2">
                                {subMenu?.items?.map(item => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-primary-500/50 duration-300 transition-all">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-primary-500/50 group-hover:bg-primary-700 duration-300 transition-all">
                                            <item.icon className="h-6 w-6 text-primary-100 group-hover:text-primary-50" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link href={baseUrl + item.href} className="font-semibold text-white !text-xs">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="text-xs text-primary-100">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 divide-x divide-primary-800 border-t border-primary-800 bg-primary-700">
                                {subMenu?.cta?.map(item => (
                                    <Link key={item.name} href={baseUrl + item.href} className="flex items-center justify-center gap-x-2.5 p-3 font-medium hover:bg-primary-600">
                                        <item.icon className="h-5 w-5 flex-none text-primary-200" aria-hidden="true" />
                                        <p className="text-xs text-primary-100">{item.name}</p>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>
        )
    }

    function ItemEntry({ item, className = '' }: { item: MenuEntry; className?: string }) {
        const styles = `px-3 max-menu:px-2 py-1.5 font-medium text-xs text-base xxs:text-xs rounded-md text-white dark:text-primary-200 block ${className} ${item.highlight ? 'xl:border' : ''}`

        if (item.href) {
            return (
                <Link
                    id={item.id}
                    key={item.name}
                    href={item.isAbsolute ? item.href : baseUrl + item.href}
                    onClick={item.onClick}
                    className={styles}
                    aria-current={item.href === activeRoute ? 'page' : undefined}
                >
                    {item.name}
                </Link>
            )
        }

        if (item.onClick) {
            return (
                <button type="button" id={item.id} key={item.name} onClick={item.onClick} className={styles}>
                    {item.name}
                </button>
            )
        }
    }

    return (
        <Disclosure as="nav" className="text-primary-50 relative z-20" id={id}>
            {({ open, close }) => {
                return (
                    <div className="h-20">
                        <div className="max-w-full mx-auto px-12 w-full bg-primary-700 dark:bg-primary-900 shadow">
                            <div className="flex items-center h-20">
                                <div className="absolute inset-y-0 left-0 flex items-center xl:hidden">
                                    {/* Mobile menu button*/}
                                    <DisclosureButton className="inline-flex items-center justify-center p-2 ml-4 rounded-md text-white hover:text-primary-50 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Hauptmenü</span>
                                        {open ? <XMarkIcon className="block h-8 w-8" aria-hidden="true" /> : <Bars3Icon className="block h-8 w-8" aria-hidden="true" />}
                                    </DisclosureButton>
                                </div>
                                <div className="flex items-center max-xl:w-full mr-12">
                                    <div className="flex-shrink-0 flex items-center max-xl:left-1/2 max-xl:transform max-xl:-translate-x-1/2 max-xl:absolute">
                                        <Link href={`${baseUrl}`}>
                                            <Image height={48} width={250} className="h-6 w-auto" src="/assets/logoDarkBg.svg" alt="Tebuto Logo" priority />
                                        </Link>
                                    </div>
                                </div>
                                <div className="w-full flex justify-between">
                                    <div className="absolute inset-y-0 left-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 my-4 z-20 hidden xl:flex 2xl:gap-6 gap-3">
                                        {leftItems.map(item => (
                                            <div key={item.name}>{item.subMenu ? <SubMenu subMenu={item.subMenu} name={item.name} /> : <ItemEntry item={item} />}</div>
                                        ))}
                                    </div>
                                    <div className="absolute inset-y-0 left-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 my-4 z-20 hidden xl:flex 2xl:gap-6 gap-3">
                                        {rightItems.map(item => (
                                            <div key={item.name}>{item.subMenu ? <SubMenu subMenu={item.subMenu} name={item.name} /> : <ItemEntry item={item} />}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DisclosurePanel className="xl:hidden bg-primary-600 relative w-full z-20 min-h-max pb-4">
                            {/* Calc 100vh-20*4px is exactl the height of the header minus the device height to allow proper scrolling */}
                            <div className="px-2 pt-4 divide-y divide-primary-500 flex flex-col gap-2">
                                {leftItems
                                    .concat(additionalMobileItems ? additionalMobileItems : [])
                                    .concat(rightItems)
                                    .map((item, index) => (
                                        <div key={`menu-item-${index}-${item.name}-${item.id}`}>
                                            <div className="pt-2" onClick={() => close()} key={item.name}>
                                                <ItemEntry item={item} />
                                            </div>
                                            {/* TODO: Disclosure; Too much items already */}
                                            {item.subMenu && (
                                                <div className="px-2 flex flex-col gap-1 mt-2 mb-4">
                                                    {item.subMenu.items.map(subItem => (
                                                        <div
                                                            className="bg-primary-700/40 border border-primary-700/70 flex items-center py-0.5 px-2 rounded-lg"
                                                            onClick={() => close()}
                                                            key={subItem.name}
                                                        >
                                                            <subItem.icon className="h-5 w-5" /> <ItemEntry className="!text-xs" item={subItem} />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </DisclosurePanel>
                    </div>
                )
            }}
        </Disclosure>
    )
}
