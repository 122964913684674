import cheerio from 'cheerio'
import React from 'react'

export function extractTextFromReactNode(node: React.ReactNode | string): string {
    if (typeof node === 'string') {
        const $ = cheerio.load(node)
        return $.root().text().trim()
    }
    if (React.isValidElement(node)) {
        return React.Children.toArray(node.props.children).map(extractTextFromReactNode).join('')
    }
    if (Array.isArray(node)) {
        return node.map(extractTextFromReactNode).join('')
    }

    return ''
}
