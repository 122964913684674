import { IBlogPost } from '@tebuto/api/blog/client'
import { PublicTherapist } from '@tebuto/api/public/models/PublicTherapist'
import { Metadata } from 'next'
import React from 'react'
import { JsonLd } from 'react-schemaorg'
import { Article, BreadcrumbList, FAQPage, Organization, Service, WebApplication, WebPage, WebSite } from 'schema-dts'
import { JsonLdWebApp } from './seo/application'
import { JsonLdArticle } from './seo/article'
import { JsonLdBlog } from './seo/blog'
import { JsonLdBreadcrumbList } from './seo/breadcrumbs'
import { JsonLdFAQ } from './seo/faq'
import { TebutoLandingMetadata } from './seo/metadata'
import { JsonLdOrganization } from './seo/organization'
import { JsonLdService } from './seo/service'
import { JsonLdTherapist } from './seo/therapist'
import { JsonLdBreadcrumb, JsonLdCityPage, JsonLdFaq, JsonLdWebPageProps, TebutoLandingMetadataProps, TebutoServiceProps } from './seo/types'
import { JsonLdWebPage } from './seo/webpage'
import { JsonLdWebsite } from './seo/website'

export namespace TebutoStructuredData {
    export function Organization(): React.ReactElement {
        return <JsonLd<Organization> item={JsonLdOrganization()} />
    }

    export function Application(): React.ReactElement {
        return <JsonLd<WebApplication> item={JsonLdWebApp()} />
    }

    export function Service({ service }: { service: TebutoServiceProps }): React.ReactElement {
        return <JsonLd<Service> item={JsonLdService(service)} />
    }

    export function FAQ({ items, name }: { items: JsonLdFaq[]; name: string }): React.ReactElement {
        return <JsonLd<FAQPage> item={JsonLdFAQ({ items, name })} />
    }

    export function LandingMeta({ ...metadata }: TebutoLandingMetadataProps): Metadata {
        return TebutoLandingMetadata(metadata)
    }

    export function BreadCrumbs({ pages = [] }: { pages?: JsonLdBreadcrumb[] }): React.ReactElement {
        return <JsonLd<BreadcrumbList> item={JsonLdBreadcrumbList({ pages: [{ name: 'Startseite', href: '/' }].concat(pages) })} />
    }

    export async function Article({ post }: { post: IBlogPost }): Promise<React.ReactElement> {
        return <JsonLd<Article> item={await JsonLdArticle({ post })} />
    }

    export function Therapist({ therapist }: { therapist: PublicTherapist }): React.ReactElement {
        return <JsonLd<Organization> item={JsonLdTherapist({ therapist })} />
    }

    export function Webpage({ ...webpage }: JsonLdWebPageProps): React.ReactElement {
        return <JsonLd<WebPage> item={JsonLdWebPage(webpage)} />
    }

    export function Blog(): React.ReactElement {
        return <JsonLd<WebPage> item={JsonLdBlog()} />
    }

    export function Website(): React.ReactElement {
        return <JsonLd<WebSite> item={JsonLdWebsite()} />
    }

    export function Defaults(): React.ReactElement {
        return (
            <>
                <Organization />
                <Application />
                <Website />
            </>
        )
    }
}
