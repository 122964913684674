'use client'

import Link from 'next/link'
import { GetFreeTrialButton } from './GetFreeTrialButton'
import { LandingSection } from './LandingComponents'
import { Matomo } from '@tebuto/providers/Matomo'

export default function CTA() {
    return (
        <LandingSection className="!px-0">
            <Matomo.ScrollTrackingAnchor sectionName={'CTA'} />
            <div className="flex flex-col sm:p-12 py-12 px-6 from-primary-500 to-primary-600 bg-gradient-to-r xl:rounded-3xl">
                <p className="text-3xl font-medium text-center text-white">Weniger Stress. Mehr Zeit und höhere Klienten&shy;zufriedenheit.</p>
                <p className="md:text-xl text-lg text-center font-light text-primary-100 max-md:pt-8">Testen Sie Tebuto 30 Tage lang kostenlos und überzeugen Sie sich selbst.</p>
                <div className="mt-16 flex max-md:flex-col gap-4 justify-between max-w-3xl w-full mx-auto">
                    <Link href={process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL as string}>
                        <GetFreeTrialButton className="h-12 font-base text-lg text-primary-600 bg-primary-100 text-center flex justify-center items-center rounded-lg lg:w-80 max-md:w-full px-3" />
                    </Link>
                    <Link href="/features">
                        <div className="h-12 font-medium text-lg text-primary-100 border-2 border-primary-100 text-center flex justify-center items-center rounded-lg lg:w-80 max-md:w-full px-3">
                            Mehr zu den Funktionen
                        </div>
                    </Link>
                </div>
            </div>
        </LandingSection>
    )
}
