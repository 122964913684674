/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EventCategoryPayload = {
    publicBookingEnabled?: boolean;
    privateBookingEnabled?: boolean;
    name: string;
    description: string | null;
    color: string;
    duration: number;
    price: string;
    outageFeeEnabled: boolean;
    outageFeeHours: number | null;
    outageFeePrice: string | null;
    currency: EventCategoryPayload.currency;
    location: EventCategoryPayload.location;
};
export namespace EventCategoryPayload {
    export enum currency {
        EUR = 'EUR',
        CHF = 'CHF',
    }
    export enum location {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
        NOT_FIXED = 'not-fixed',
    }
}

