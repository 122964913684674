'use client'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { ReactNode } from 'react'

interface FAQ {
    question: string
    answer: string | ReactNode
}

export function FAQContainer({ children, className }: { children: ReactNode; className?: string }): JSX.Element {
    return <dl className={`grid space-y-6 divide-y divide-gray-900/10 ${className ? className : ''}`}>{children}</dl>
}

export function FAQItem({ question, answer }: FAQ): JSX.Element {
    return (
        <Disclosure as="div" className="pt-6">
            {({ open }) => (
                <>
                    <dt>
                        <DisclosureButton className="flex w-full items-start justify-between text-left">
                            <span className="text-landing font-semibold leading-7 text-gray-700">{question}</span>
                            <span className="ml-6 flex h-7 items-center">
                                {open ? <MinusIcon className="h-6 w-6" aria-hidden="true" /> : <PlusIcon className="h-6 w-6" aria-hidden="true" />}
                            </span>
                        </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                        <p className="text-lg leading-8 my-6 font-sans">{answer}</p>
                    </DisclosurePanel>
                </>
            )}
        </Disclosure>
    )
}
