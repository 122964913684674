'use client'

import { DEFAULT_ENV_VARIABLES, RuntimeEnvConfig, getBooleanEnvVariable, getEnvVariablesWithDefaults } from '@/config/env'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

const EnvVariablesClientContext = createContext<RuntimeEnvConfig>(DEFAULT_ENV_VARIABLES as RuntimeEnvConfig)

type EnvClientProviderProps = {
    children: ReactNode
}

export function RuntimeEnvProvider({ children }: EnvClientProviderProps) {
    const [envs, setEnvs] = useState<RuntimeEnvConfig>(DEFAULT_ENV_VARIABLES)

    useEffect(() => {
        const isSSR = typeof window === 'undefined'
        if (isSSR) {
            setEnvs(
                getEnvVariablesWithDefaults({
                    flags: {
                        connectZoomEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_ZOOM_ENABLED'),
                        connectMicrosoftTeamsEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_MICROSOFT_TEAMS_ENABLED'),
                        connectGoogleCalendarEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_GOOGLE_CALENDAR_ENABLED'),
                        connectOutlookEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_OUTLOOK_ENABLED'),
                        publicBookingEnabled: getBooleanEnvVariable('FEATURE_FLAG_PUBLIC_BOOKING_ENABLED')
                    }
                })
            )
        } else {
            fetch(`${process.env.NEXT_PUBLIC_BFF_BASE_URL}/runtimeEnv`)
                .then(res => res.json())
                .then(runtimeEnvs => {
                    setEnvs(getEnvVariablesWithDefaults(runtimeEnvs))
                })
                .catch(error => {
                    console.error('Failed to fetch runtime environment variables:', error)
                })
        }
    }, [])

    return <EnvVariablesClientContext.Provider value={envs}>{children}</EnvVariablesClientContext.Provider>
}

export function useRuntimeEnv(): RuntimeEnvConfig {
    const context = useContext(EnvVariablesClientContext)
    if (context === undefined) {
        throw new Error('useRuntimeEnv must be used within a RuntimeEnvProvider')
    }
    return context
}
