/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Booking } from './Booking';
export type ClientWithBookings = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    gender: ClientWithBookings.gender;
    isActive: boolean;
    deleted: boolean;
    notes: string | null;
    status: ClientWithBookings.status;
    bookings: Array<Booking>;
};
export namespace ClientWithBookings {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
    export enum status {
        NEW = 'new',
        CLIENT = 'client',
    }
}

