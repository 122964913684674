'use client'

import { Matomo } from '@tebuto/providers/Matomo'
import { Heading, LandingSection } from './LandingComponents'
import Link from 'next/link'
import LinkButton from '@tebuto/buttons/Link'
import { Button } from '@tebuto/buttons/Button'

interface RoadMapItem {
    title: string
    description: string
    readMoreLink: string
}

const roadMapItems: RoadMapItem[] = [
    {
        title: 'Zahlungen & Buchhaltung',
        description:
            'Mit diesem Feature integrieren wir Zahlungen direkt in Tebuto. Ihre Klienten können Termine bequem per Lastschrift, Apple Pay, Google Pay, Kreditkarte oder Rechnung bezahlen. Das reduziert den Aufwand für Sie und vereinfacht die Bezahlung für Ihre Klientinnen. Und damit das für Sie möglichst einfach wird, integrieren wir die beiden gängigsten Buchhaltungstools LexOffice und SevDesk. So werden Zahlungen nahtlos übermittelt.',
        readMoreLink: '/roadmap/zahlungen-und-buchhaltung'
    },
    {
        title: 'Warteliste',
        description:
            'Die meisten Therapeut:innen führen eine Warteliste, da die Nachfrage noch immer deutlich höher als das Angebot ist. Mit diesem Feature möchten wir Sie beim Erstellen und Verwalten einer Warteliste unterstützen. Potentielle Klienten können sich eintragen und werden automatisch benachrichtigt, wenn nachgerückt werden kann. Damit bieten Sie eine faire und einfache Lösung und müssen potentiellen Klienten nicht länger manuell mitteilen, dass Sie sich in einigen Wochen oder Monaten wieder melden sollen.',
        readMoreLink: '/roadmap/warteliste'
    },
    {
        title: 'Dokumentation',
        description:
            'Bei jedem Termin wird an der Lösung eines Problems gearbeitet. Um das Ziel nicht aus den Augen zu verlieren, dokumentieren die meisten Therapeut:innen Ihre Sitzungen. Auch dabei möchten wir Sie unterstützen. Textbausteine helfen dabei, sich wiederholende Elemente wie Anamnese, Therapieplan und Hausaufgaben mit wenig Aufwand festzuhalten.',
        readMoreLink: '/roadmap/dokumentation'
    }
]

export default function RoadMapFeatures() {
    return (
        <LandingSection>
            <Matomo.ScrollTrackingAnchor sectionName={'Roadmap'} />
            <p className="sm:text-sm text-xs font-semibold text-primary-600">Was wir für die nahe Zukunft planen</p>
            <Heading>Unsere Roadmap</Heading>
            <div className="pt-12">
                <p className="text-landing">
                    Uns ist es wichtig, nicht irgendeine Software zu entwickeln, sondern <b className="text-landing">Ihre Software</b>. Wir möchten Sie bei Ihrer Arbeit bestmöglich
                    entlasten. Dafür benötigen wir <b className="text-landing">Ihre Mithilfe.</b> Auf unserer Roadmap können Sie uns Ihre Ideen, Wünsche und Anliegen mitteilen,
                    damit wir diese berücksichtigen und gemeinsam Lösungen entwickeln können. Wir freuen uns auf Ihre Anregungen!
                </p>
                <div className="mt-6 w-full flex justify-end">
                    <Button className="w-max" href="/roadmap">
                        Zur Roadmap
                    </Button>
                </div>
            </div>
            <div className="grid gap-6 mt-24">
                {roadMapItems.map((roadMapItem, index) => (
                    <RoadMapItem roadMapItem={roadMapItem} key={index} />
                ))}
            </div>
        </LandingSection>
    )
}

function RoadMapItem({ roadMapItem }: { roadMapItem: RoadMapItem }) {
    return (
        <div className="bg-white flex flex-col gap-4 px-8 py-10 rounded-3xl shadow-md border-gray-300 border">
            <h2 className="font-medium text-gray-800 md:text-3xl text-2xl">{roadMapItem.title}</h2>
            <p className="text-landing">{roadMapItem.description}</p>
            <a href={roadMapItem.readMoreLink} className="text-primary-600 text-landing font-medium self-end">
                Mehr dazu
            </a>
        </div>
    )
}
