import MediumText from '@tebuto/typography/MediumText'

export function Heading({
    children,
    className,
    isH1,
    ...rest
}: { children: React.ReactNode; className?: string; isH1?: boolean; rest?: React.HTMLAttributes<HTMLHeadingElement> }) {
    if (isH1) {
        return (
            <h1
                className={`hero:text-7xl lg:text-5xl text-3xl leading-[1.1] font-semibold text-gray-900 tracking-tighter font-sans text-balance hyphens-auto ${className ? className : ''}`}
                {...rest}
            >
                {children}
            </h1>
        )
    }

    return (
        <h2
            className={`hero:text-7xl lg:text-5xl text-3xl leading-[1.1] font-semibold text-gray-900 tracking-tighter font-sans text-balance hyphens-auto ${className ? className : ''}`}
            {...rest}
        >
            {children}
        </h2>
    )
}

export function LandingSection({ children, className, id, ...rest }: { children: React.ReactNode; className?: string; id?: string; rest?: React.HTMLAttributes<HTMLDivElement> }) {
    return (
        <section id={id} className={`max-w-6xl mx-auto max-xl:px-6 py-32 ${className ? className : ''}`} {...rest}>
            {children}
        </section>
    )
}

export interface IFeaturePoint {
    title: string
    description: string
    Icon: any
}

export function FeaturePoint({ feature: { Icon, title, description } }: { feature: IFeaturePoint }) {
    return (
        <div className="flex gap-6">
            <Icon className="lg:size-14 md:size-16 size-12 text-primary-600 stroke-[0.5] max-md:!stroke-1 flex-shrink-0 flex-grow-0 max-sm:hidden" />
            <div>
                <div className="flex gap-2 items-center max-sm:mb-4">
                    <Icon className="size-8 text-primary-600 !stroke-1 flex-shrink-0 flex-grow-0 sm:hidden" />
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: Trust me bro */}
                    <p className="font-medium lg:text-2xl text-xl text-gray-700" dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <MediumText className="mt-3 text-justify hyphens-auto">{description}</MediumText>
            </div>
        </div>
    )
}
